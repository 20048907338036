import { STAFF_ACTION_TYPES } from './actionTypes';
import clientService from '../services/client';

//////////////////// ACTIONS  //////////////////////

export const getEmployees = () => {
    return {
        type: STAFF_ACTION_TYPES.GET_STAFF
    }
};

export const getEmployeesSuccess = (employees) => {
    return {
        type: STAFF_ACTION_TYPES.GET_STAFF_SUCCESS,
        data: employees
    }
};

export const getEmployeesFailed = (error) => {
    return {
        type: STAFF_ACTION_TYPES.GET_STAFF_FAIL,
        data: error
    }
};

export const getEmployeeById = () => {
    return {
        type: STAFF_ACTION_TYPES.GET_STAFF_BY_ID
    }
};

export const getEmployeeByIdSuccess = (employee) => {
    return {
        type: STAFF_ACTION_TYPES.GET_STAFF_BY_ID_SUCCESS,
        data: employee
    }
};

export const getEmployeeByIdFailed = (error) => {
    return {
        type: STAFF_ACTION_TYPES.GET_STAFF_BY_ID_FAIL,
        data: error
    }
};

export const editEmployee = () => {
    return {
        type: STAFF_ACTION_TYPES.EDIT_EMPLOYEE
    }
};

export const editEmployeeSuccess = (successMessage) => {
    return {
        type: STAFF_ACTION_TYPES.EDIT_EMPLOYEE_SUCCESS,
        data: successMessage
    }
};

export const editEmployeeFailed = (error) => {
    return {
        type: STAFF_ACTION_TYPES.EDIT_EMPLOYEE_FAIL,
        data: error
    }
};

export const removeEnrollee = () => {
    return {
        type: STAFF_ACTION_TYPES.REMOVE_EMPLOYEE
    }
};

export const removeEnrolleeSuccess = (message) => {
    return {
        type: STAFF_ACTION_TYPES.REMOVE_EMPLOYEE_SUCCESS,
        data: message
    }
};

export const removeEnrolleeFailed = (error) => {
    return {
        type: STAFF_ACTION_TYPES.REMOVE_EMPLOYEE_FAIL,
        data: error
    }
};

export const clearNotification = () => {
    return {
        type: STAFF_ACTION_TYPES.CLEAR_NOTIFICATION,
    }
}


////////////////////// THUNKS  ////////////////////////////


export const getClientEmployees = (accessToken, clientId, invoiceRefCode) => {
    return ((dispatch) => {
        dispatch(getEmployees());
        clientService.getStaff(accessToken, clientId, invoiceRefCode)
            .then((employees) => {
                dispatch(getEmployeesSuccess(employees));
            }, (err) => {
                dispatch(getEmployeesFailed({ message: err.response.data.message, code: err.response.status }))
            })
    });
};

export const getClientEmployeeById = (id, accessToken, clientId) => {
    return ((dispatch) => {
        dispatch(getEmployeeById());
        clientService.getStaffById(id, accessToken, clientId)
            .then((employee) => {
                dispatch(getEmployeeByIdSuccess(employee));
            }, (err) => {
                dispatch(getEmployeeByIdFailed({ message: err.response.data.message, code: err.response.status }));
            })
    })
};

export const editClientEmployee = (userId, firstname, lastname, email, phone, accessToken, clientId) => {
    return ((dispatch) => {
        dispatch(editEmployee());
        clientService.editEmployee(userId, firstname, lastname, email, phone, accessToken, clientId)
            .then((message) => {
                dispatch(editEmployeeSuccess(message));
            }, (err) => {
                //console.log(err);
                dispatch(editEmployeeFailed(
                    {
                        message: err.response.data.message || "Oops!...Something seems to have gone wrong. Please contact hellonigeria@getreliancehealth.com for support.",
                        code: err.response.status
                    }));
            })
    })
};

export const removeEnrolleeById = (id, accessToken, clientId) => {
    return ((dispatch) => {
        dispatch(removeEnrollee());
        clientService.removeEnrollee(id, accessToken, clientId)
            .then(response => {
                dispatch(removeEnrolleeSuccess(response));
            }, (err) => {
                dispatch(removeEnrolleeFailed(
                    {
                        message: err.response.data.message || "Oops!...Something seems to have gone wrong. Please contact hellonigeria@getreliancehealth.com for support.",
                        code: err.response.status
                    }));
            })
    })
};