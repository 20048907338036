import { createTypes, async } from 'redux-action-types';
import * as ACTION_CATEGORIES from './actionCategories';

export const CLIENT_ACTION_TYPES = createTypes(ACTION_CATEGORIES.CLIENT+'_',
    async('LOGIN'),
    async('DAARA_LOGOUT'),
    async('GET_OVERVIEW'),
    async('GET_USAGE'),
    async('UPDATE_PROFILE'),
    async('CHANGE_LOGO')
);

export const STAFF_ACTION_TYPES = createTypes(ACTION_CATEGORIES.STAFF+'_',
    async('GET_STAFF'),
    async('GET_STAFF_BY_ID'),
    async('EDIT_EMPLOYEE'),
    async('REMOVE_EMPLOYEE'),
    'CLEAR_NOTIFICATION'  
);

export const INVOICE_ACTION_TYPES = createTypes(ACTION_CATEGORIES.INVOICES+'_',
    async('GET_INVOICES'),
    async('GET_INVOICE_BY_REF_CODE'),
    async('GET_INVOICE_PLANS'),
    async('ADD_EMPLOYEE_TO_INVOICE')
);

export const DISPLAY_ACTION_TYPES = createTypes(ACTION_CATEGORIES.DISPLAY+'_',
    'TOGGLE_SIDEBAR',
    'HIDE_SIDEBAR'
);