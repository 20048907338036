import React from 'react';
import {Field, change, reduxForm} from 'redux-form';

class EditEmployeeForm extends React.Component{

    componentDidMount(){
        const {dispatch, accessToken, user, clientId, plans} = this.props;
        dispatch(change("editEmployeeForm", "access_token",accessToken));
        dispatch(change("editEmployeeForm", "user_id",user.id));
        dispatch(change("editEmployeeForm", "first_name",user.first_name));
        dispatch(change("editEmployeeForm", "last_name",user.last_name));
        dispatch(change("editEmployeeForm", "email_address",user.email_address));
        dispatch(change("editEmployeeForm", "phone_number",user.phone_number));
        dispatch(change("editEmployeeForm", "product_id",plans.filter(plan => plan.name.toLowerCase().trim() === user.product.name.toLowerCase().trim())));
        dispatch(change("editEmployeeForm", "client_id", clientId))
    }

    render(){
        const {handleSubmit, pristine, submitting, plans} = this.props;
        return (
            <form onSubmit={handleSubmit} style={{display:"flex", alignItems:"center",flexDirection:"column"}}>
                <Field component="input" className="form-input" type="text" name="first_name" placeholder="First Name"/>
                <Field component="input" className="form-input" type="text" name="last_name" placeholder="Last Name"/>
                <Field component="input" className="form-input" type="text" name="email_address" placeholder="Email Address"/>
                <Field component="input" className="form-input" type="text" name="phone_number" placeholder="Phone Number"/>
                <Field component="select" className="form-input" name="product_id">
                    {plans.map((plan)=>(
                        <option key={plan.id} value={plan.id}>{plan.name}</option>
                    ))}
                </Field>
                <Field component="input" type="hidden" name="user_id" style={{display:"none"}}/>
                <Field component="input" type="hidden" name="client_id" style={{display:"none"}}/>
                <Field component="input" type="hidden" name="access_token" style={{display:"none"}}/>
                <button className="form-button button_link" disabled={pristine||submitting}>
                    Save Changes
                </button>
            </form>
        )
    }
}



EditEmployeeForm = reduxForm({
    form:"editEmployeeForm"
})(EditEmployeeForm);

export default EditEmployeeForm;