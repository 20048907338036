import React  from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import LoginContainer from './containers/LoginContainer';
import DashboardComponent from './components/DashboardComponent';
import PageNotFound from './components/PageNotFound';
import './App.css';
import AccountSelectionComponent from "./components/AccountSelectionComponent";
import { withUser } from "./components/utils/AuthHOC";

const App = () => (
    <Router history="">
      <div className="content">
        <Switch>
          <Route path="/" exact render={(props)=> <Redirect to="/dashboard" /> } />
          <Route component={LoginContainer} path="/login" exact />
          <Route component={withUser(DashboardComponent)} path="/dashboard" />
          <Route component={AccountSelectionComponent} path="/select-account"/>
          <Route component={PageNotFound}/>
        </Switch>
      </div>
    </Router>
);

export default App;
