import { STAFF_ACTION_TYPES } from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const { 
    GET_STAFF, GET_STAFF_BY_ID, GET_STAFF_BY_ID_FAIL,
    GET_STAFF_BY_ID_SUCCESS, GET_STAFF_FAIL, GET_STAFF_SUCCESS, 
    EDIT_EMPLOYEE, EDIT_EMPLOYEE_FAIL, EDIT_EMPLOYEE_SUCCESS,
    REMOVE_EMPLOYEE, REMOVE_EMPLOYEE_FAIL, REMOVE_EMPLOYEE_SUCCESS,
    CLEAR_NOTIFICATION,
    } = STAFF_ACTION_TYPES

const initialState = Immutable({
    isFetching:false,
    allEmployees:[],
    selectedEmployee:{},
    error:{},
    alert:""
});

export default function staffReducer(state=initialState, action){
    switch (action.type){
        case GET_STAFF:
        case GET_STAFF_BY_ID:
        case EDIT_EMPLOYEE:
        case REMOVE_EMPLOYEE:
            return Immutable(state).merge({
                isFetching:true
            });
        case GET_STAFF_SUCCESS:
            return Immutable(state).merge({
                isFetching:false,
                allEmployees:[...action.data],
                error:{}
            },{deep:true});
        case GET_STAFF_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error:action.data
            });
        case GET_STAFF_BY_ID_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                selectedEmployee:action.data,
                error:{}
            });
        case GET_STAFF_BY_ID_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            });
        case EDIT_EMPLOYEE_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                alert:action.data,
                error:{}
            });
        case EDIT_EMPLOYEE_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            },{deep:true});
        case REMOVE_EMPLOYEE_SUCCESS:
            const selectedEmployee = {...state.selectedEmployee, isDeleted: true};
            return Immutable(state).merge({
                isFetching: false,
                alert: action.data,
                selectedEmployee: selectedEmployee,
                error:{}
            },{deep:true});
        case REMOVE_EMPLOYEE_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            });
        case CLEAR_NOTIFICATION:
            return Immutable(state).merge({
                alert: ''
            })
        default:
            return state;
    }
}
