import React from 'react';
import { connect } from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import numberFormatter from 'number-formatter';
import Immutable from 'seamless-immutable';
import * as invoiceActions from '../actions/invoice';
import PageNotFound from './PageNotFound';
import LoadingScreen from './LoadingScreen';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import MainContent from './boxes/MainContent';
import Modal from './boxes/Modal';
import NotificationBanner from './boxes/NotificationBanner'
import PaymentFrequencySelector from './PaymentFrequencySelector';

class InvoiceDetailsComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            currentTab:"details",
            addEmployee:false,
            renderEnrollees: false,
            openPaymentFrequencyModal: false,
            notification: null
        };
    };

    componentDidMount(){
        const {dispatch, user, match} = this.props;
        dispatch(invoiceActions.getClientInvoiceByRefCode(match.params.invoiceId, user.credentials.accessToken,user.companyInfo.id));
    }

    onSwitchTab = () => {
        this.setState({
            currentTab:this.state.currentTab === "details" ? "payments" : "details"
        })
    };

    togglePaymentFrequencyModal = () => {
        this.setState({openPaymentFrequencyModal: !this.state.openPaymentFrequencyModal})
    }

    onFrequencyChanged = (err, message) => {
        const notification = {
            type: err ? "Error" :  "Success",
            message: err ? err : message,
        }
        this.setState({notification})
        this.togglePaymentFrequencyModal();
      };

    renderLoading(){
        return (
            <MainContent>
                <PageBody>
                    <LoadingScreen/>
                </PageBody>
            </MainContent>
        )
    }

    renderInvoiceNotFound(){
        return (
            <MainContent>
               <PageNotFound/>
            </MainContent>
        )
    }

    goToEnrollees = () => {
        this.setState({renderEnrollees: true})
    }

    renderEnrolleesPage = () => {
        return (<Redirect to={{
            pathname: `/dashboard/staff`,
            state: {
                invoiceRefCode: this.props.selectedInvoice.reference_code
            }
        }}/>)
    }

    render(){

        const { currentTab, renderEnrollees, openPaymentFrequencyModal, notification } = this.state;
        const { isFetching, error, selectedInvoice, user, clientType } = this.props;

        if(error && error.code === 404) return this.renderInvoiceNotFound();

        if(renderEnrollees) return this.renderEnrolleesPage();

        if(!isFetching && !_.isEmpty(selectedInvoice))
        return (
            <MainContent>
                {openPaymentFrequencyModal && 
                    <FrequencyModal
                        onClose={this.togglePaymentFrequencyModal}
                        onFrequencyChanged={this.onFrequencyChanged}
                        invoice={selectedInvoice}
                        user={user}
                        selectedFrequency={getPaymentFrequency(selectedInvoice.payment_frequency_id).toLowerCase()}/>
                }
                <PageHeader title="Invoice Details" showButton buttonTitle="View Enrollees" onButtonClick={this.goToEnrollees}/>
                <PageBody>
                    {notification &&
                        <NotificationBanner
                            title={notification.type}
                            message={notification.message}
                            type={notification.type.toLowerCase()}/>
                    }
                    <div className="dash__tab">
                        <button
                            className={`tablinks ${currentTab === 'details' ? 'activeTab' : '' }`}
                            onClick={this.onSwitchTab}>
                            <h3 className="invoice-expanded__headertext">Invoice for {selectedInvoice.reference_code}</h3>
                        </button>
                        <button
                            className={`tablinks ${currentTab === 'payments' ? 'activeTab' : '' }`}
                            onClick={this.onSwitchTab}>
                            <h3 className="invoice-expanded__headertext">Payments</h3>
                        </button>
                    </div>
                    <div className="invoice-expanded">
                        {currentTab === "details" ? (
                                <DetailsView 
                                    invoice={selectedInvoice} 
                                    plans={selectedInvoice.products}
                                    showFrequencyModal={this.togglePaymentFrequencyModal}
                                    clientType={clientType}
                                    />
                            ) : (
                                <PaymentsView payments={selectedInvoice.payments}/>
                            )}
                    </div>
                </PageBody>
            </MainContent>
        );
        return this.renderLoading();
    }

}


const DetailsView = ({invoice, plans, showFrequencyModal, clientType}) => {
    let amount_paid = invoice.payments && invoice.payments.reduce((totalAmountPaid, currentPayment) => +(totalAmountPaid + currentPayment.amount), 0)
    return(
        <div
            className="invoice-expanded__table tabDashboard"
            style={{display:'flex'}}>
            <div className="dashboard_profile">
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                    <div style={{display:"flex", flexWrap:"wrap" }}>
                        <div className="profile__info" style={{paddingBottom: "0"}}>
                            <p className="profile__label" >DATE ISSUED</p>
                            <p className="profile__infotext">{moment(invoice.created_at).format("DD/MM/YYYY")}</p>
                            <p className="profile__label">PAYMENT STATUS</p>
                            <p className="profile__infotext">{getInvoiceStatusIndicator(invoice.payment_status_id)}</p>
                        </div>
                        <div className="profile__info" style={{paddingBottom: "0"}}>
                            <p className="profile__label" >AMOUNT PAID</p>
                            <p className="profile__infotext">NGN{numberFormatter("#,###.00",amount_paid)}</p>
                            <p className="profile__label">BALANCE DUE</p>
                            <p className="profile__infotext">NGN{numberFormatter("#,###.00",parseFloat(invoice.total_price) - parseFloat(amount_paid))}</p>
                        </div>
                        <div className="profile__info" style={{paddingBottom: "0"}}>
                            <p className="profile__label">COVER START DATE</p>
                            <p className="profile__infotext">{moment(invoice.start_date).format("DD/MM/YYYY")}</p>
                            <p className="profile__label" >COVER END DATE</p>
                            <p className="profile__infotext">{moment(invoice.end_date).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="profile__info" style={{justifyContent: "normal", paddingBottom: "0"}}>
                            <p className="profile__label" >FREQUENCY</p>
                            <p className="profile__infotext">{getPaymentFrequency(invoice.payment_frequency_id)}</p>
                             
                             {(clientType === 'SME') && 
                             <button className="primary_button" style={{margin: 0}} onClick={showFrequencyModal}>
                                Change Frequency 
                            </button>
                            }
                        </div>
                    </div>
                    {invoice.new_frequency_id && 
                        <p style={{padding: "20px", fontSize: "1.4em", color: "grey"}}><span style={{color: "#449802", textTransform: "uppercase"}}>{getPaymentFrequency(invoice.new_frequency_id)}</span> frequency begins at the next cycle</p>
                    }
                </div>
                <br/>
            </div>
            <div style={{margin: "0 2rem", overflowX: "scroll"}}>
                <PlansView plans={plans}/>
            </div>
        </div>
    )
};

const PlansView = ({plans}) => {
    return (
        <table className="dashboard__table grey_bg">
            <thead>
            <tr>
                <th>plan description</th>
                <th>employee(s)</th>
                <th>amount (NGN)</th>
                <th>enrollees left</th>
            </tr>
            </thead>
            <tbody>
            {plans.map((plan,index)=>(
                <tr key={index}>
                    <td>{plan.name}</td>
                    <td>{plan.no_of_enrollees}</td>
                    <td>{numberFormatter("#,###.00",plan.price)}</td>
                    <td>{plan.enrollees_left}</td>
                </tr>
            ))}
            </tbody>
        </table>
    )
};

const PaymentsView = ({payments}) => {
  return (
      <div
          className="invoice-expanded__payments tabDashboard"
          style={{display:'flex'}}>
          <table className="dashboard__table">
              <thead>
              <tr>
                  <th>Date Paid</th>
                  <th>Amount (NGN)</th>
                  <th>Description</th>
              </tr>
              </thead>
              <tbody>
              {payments.map((payment,index)=>(
                  <tr key={index}>
                      <td>{moment(payment.created_at).format("DD/MM/YYYY h:mm:ss a")}</td>
                      <td>{numberFormatter("#,###.00",payment.amount)}</td>
                      <td>{payment.description || 'No Description'}</td>
                  </tr>
              ))}
              </tbody>
          </table>
      </div>
  )
};

const FrequencyModal = ({selectedFrequency, onClose, invoice, user, onFrequencyChanged}) => {

    return (
      <Modal open={true} onClose={onClose} title="Change Payment Frequency">
        {/* show frequency options...along with their respective prices */}
        <PaymentFrequencySelector
          user={user}
          invoice={invoice}
          exclude={selectedFrequency}
          onFrequencyChanged={onFrequencyChanged}/>
      </Modal>
    )
  };

const getInvoiceStatusIndicator = (status) => {
    if(status === 1){
        return 'No Payment';
    }else if(status === 2){
        return 'Partial Payment';
    }else{
        return 'Payment Complete';
    }
};

const getPaymentFrequency = (id) => {
    switch (id) {
        case 1:
            return 'Daily';
        case 2:
            return 'Weekly';
        case 3:
            return 'Monthly';
        case 4:
            return 'Yearly';
        case 5:
            return 'Biennially';
        case 6:
            return 'Endlessly';
        case 7:
            return 'Quarterly';
        case 8:
            return 'Biannually';
        case 9:
            return 'Triannually';
        default:
            return 'Monthly';
    }
};

const mapStateToProps = ({invoice, session}) => ({
    isFetching: invoice.isFetching,
    selectedInvoice: invoice.selectedInvoice ? Immutable.asMutable(invoice.selectedInvoice, {deep:true}) : {},
    error: invoice.error,
    clientType: session?.user?.companyInfo?.type
});

export default withRouter(connect(mapStateToProps)(InvoiceDetailsComponent));