import React from 'react';
import * as _ from 'lodash';
import numberFormatter from 'number-formatter'
import PropTypes from 'prop-types';
import clientService from '../services/client';
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import Modal from "./boxes/Modal";

class NewInvoiceComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            plans:[],
            paymentFrequency:"monthly",
            selectedPlans:[],
            showCheckoutModal: false,
            isLoading: true,
            isAllowed: props.user.companyInfo.canModify,
            error:null
        }
    }


    componentDidMount(){
        //get plans
        //set plans state object
        this.setLoading(true);
        clientService.getAllPlans()
            .then(plans=>{
                this.setState({
                    plans:plans
                });
                this.setLoading(false);
            })
    }

    setLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        })
    };

    setPaymentFrequency = (selectedFreq) => {
        let newFrequency = "monthly";
        if(selectedFreq === 1){
            newFrequency = "quarterly";
        }else if(selectedFreq === 2){
            newFrequency = "yearly";
        }

        this.setState({
            paymentFrequency:newFrequency
        })

    };

    onAddToPlanList = (planId, planName, planPrice, quantity) => {
      const plan = this.state.selectedPlans.filter((plan)=>plan.id === planId)[0] || null;
      if(!plan){
          this.setState({
              selectedPlans:[...this.state.selectedPlans, {id:planId, name: planName, price:planPrice, quantity: quantity}]
          })
      }else{
          const oldSP = this.state.selectedPlans;
          this.setState({
              selectedPlans: oldSP.map(p=>p.id === plan.id ? {id:planId, name: planName, price:planPrice, quantity: quantity}:p)
          })
      }
    };

    onRemoveFromPlanList = (id) => {
        const oldSP = this.state.selectedPlans;
        _.remove(oldSP,(plan)=>plan.id === id);
        this.setState({
            selectedPlans: oldSP
        })
    };

    toggleCheckoutModal = () => {
        this.setState({
            showCheckoutModal:!this.state.showCheckoutModal
        })
    };

    onCheckout = (newInvoice, card_id) => {
        const {user} = this.props;
        newInvoice.meta = {
            payment_frequency: this.state.paymentFrequency[0],
            for: "sme",
            card_id,
            client: {
                id: user.preferences.selectedClient
            }
        };

        this.toggleCheckoutModal();

        this.setLoading(true);
        clientService.newInvoice(newInvoice, user.credentials.accessToken)
            .then(response=>{
                this.setLoading(false);
                if (response.authorization_url !== "") {
                    window.location.href = response.authorization_url;
                } else {
                    this.props.history.push('/dashboard/invoices')
                }
            }, e=> {
                this.setLoading(false);
                this.setState({
                    error: {
                        message: e.response.data.message
                    }
                })
            });
    };

    redirectToDashboard = () => {
        window.location.href = "/dashboard";
    };

    dismissErrorModal = () => {
        this.setState({
            error:null
        })
    };


    render(){

        const {selectedPlans,paymentFrequency,plans, showCheckoutModal, isLoading, isAllowed, error} = this.state;
        const {user} = this.props

        if(!isAllowed) return (
            <MainContent>
                <NotificationModal
                    message={`Sorry! But you're not allowed to carry out this operation.
                                        If you need to purchase new plans, please contact our support via hellonigeria@getreliancehealth.com
                                        or call 070073542623. Thank You.`}
                    onClose={this.redirectToDashboard}
                />
            </MainContent>
        );

        if(isLoading) return (<MainContent><LoadingScreen/></MainContent>);

        if(!isLoading) {
            return (
                <MainContent>
                    {showCheckoutModal &&
                        <OrderSummaryModal
                            plans={selectedPlans}
                            clientDetails={{client_id: user.preferences.selectedClient, accessToken: user.credentials.accessToken}}
                            paymentFreq={paymentFrequency}
                            onCheckout={this.onCheckout}
                            onClose={this.toggleCheckoutModal}/>
                    }
                    {error &&
                        <NotificationModal
                            message={error.message}
                            onClose={this.dismissErrorModal}
                        />
                    }
                    <PageHeader title="Create New Invoice"/>
                    <PageBody>
                        <CheckOutHeader selectedPlans={selectedPlans} onRemoveFromPlanList={this.onRemoveFromPlanList} onCheckout={this.toggleCheckoutModal}/>
                        <div className="plan-selection-wrapper">
                            <div className="plan-selection__header-wrapper">
                                <h2>Select as many plans as you want, as well as a comfortable payment plan.
                                    Please note that subsequent payments will be debited automatically from your card
                                </h2>
                            </div>
                            <div className="plan-selection__duration-wrapper">
                                <button className={`plan_duration_btn ${paymentFrequency === "monthly" ? "plan_duration_btn_active": ""} lpdbc`} onClick={()=>this.setPaymentFrequency(0)}>Monthly</button>
                                <button className={`plan_duration_btn ${paymentFrequency === "quarterly" ? "plan_duration_btn_active": ""}`} onClick={()=>this.setPaymentFrequency(1)}>Quarterly</button>
                                <button className={`plan_duration_btn ${paymentFrequency === "yearly" ? "plan_duration_btn_active": ""} rpdbc`} onClick={()=>this.setPaymentFrequency(2)}>Annually</button>
                            </div>
                            <div className="plan-selection__plans-wrapper">
                                <div className="plan_container">
                                    <div className="plan_item">
                                        <h4 className="plan_name">Red Beryl Plan</h4>
                                        <p className="plan_small_desc"><span className="provider-count redBeryl">Access to 80 hospitals</span>, Coverage for Medical Expenses up to &#8358;1.2 Million per year,Eyecare
                                            up to &#8358;15,000...</p>
                                        <a className="plan_small_link" href="https://reliancehmo.com/benefits.html">View comprehensive list of health benefits</a>
                                        {/*<a className="plan_small_link show-providers-btn redBeryl" href="">View list of Accessible Hospitals</a>*/}
                                    </div>

                                    {plans.filter(plan=>plan.name.toLowerCase().indexOf("red beryl") > -1)
                                        .map(plan=>{
                                            return (
                                                <PlanComponent
                                                    key={plan.id}
                                                    planId={plan.id}
                                                    planName={plan.name}
                                                    planType={plan.type}
                                                    planPrice={plan.price[paymentFrequency]}
                                                    frequency={paymentFrequency.substr(0,paymentFrequency.length-2)}
                                                    onAddPlan={this.onAddToPlanList}
                                                    isAdded={selectedPlans.filter((p)=>p.id === plan.id).length > 0}
                                                />
                                            )
                                        })
                                    }

                                </div>
                                <div className="plan_container">
                                    <div className="plan_item">
                                        <h4 className="plan_name">Alexandrite Plan</h4>
                                        <p className="plan_small_desc"><span className="provider-count alexandrite">Access to 100 hospitals</span>, Coverage for Medical Expenses up to &#8358;1.8 Million per year,Eyecare
                                            up to &#8358;25,000...</p>
                                        <a className="plan_small_link" href="https://reliancehmo.com/benefits.html">View comprehensive list of health benefits</a>
                                        {/*<a className="plan_small_link show-providers-btn alexandrite" href="">View list of Accessible Hospitals</a>*/}
                                    </div>

                                    {plans.filter(plan=>plan.name.toLowerCase().indexOf("alexandrite") > -1)
                                        .map(plan=>{
                                            return (
                                                <PlanComponent
                                                    key={plan.id}
                                                    planId={plan.id}
                                                    planName={plan.name}
                                                    planType={plan.type}
                                                    planPrice={plan.price[paymentFrequency]}
                                                    frequency={paymentFrequency.substr(0,paymentFrequency.length-2)}
                                                    onAddPlan={this.onAddToPlanList}
                                                    isAdded={selectedPlans.filter((p)=>p.id === plan.id).length > 0}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </PageBody>
                </MainContent>
            )
        }
    }
}

class CheckOutHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            openDropDown:false
        }
    }

    toggleDropDown = () => {
        this.setState({
            openDropDown: !this.state.openDropDown
        })
    };

    render(){
        const {selectedPlans, onRemoveFromPlanList, onCheckout} = this.props;
        return (
            <div className={`checkout-container ${selectedPlans.length > 0 ? "": "hide"}`}>
                <div className="box-shadow checkout-header">
                    <div className="checkout-header__plan-count" onClick={this.toggleDropDown}>
                        <span>
                            <i className={this.state.openDropDown ? "fa fa-chevron-down":"fa fa-chevron-right"}/>
                            &nbsp;&nbsp;&nbsp;
                        </span>
                        <h3>{`  ${selectedPlans.reduce((sum,plan)=>sum+plan.quantity, 0)} Plan(s) Selected`}</h3>
                        <br/>
                        <h5>(Click to expand)</h5>
                    </div>
                    <div>
                        <button
                            className="checkout-header__button" type="submit" onClick={onCheckout}>
                            Click to Proceed
                        </button>
                    </div>
                </div>
                <div className={`box-shadow checkout-details ${this.state.openDropDown ? "active":""}`}>
                    <ol className="checkout-details__plan-list">
                        {selectedPlans.map(plan=>{
                            return (
                                <li key={plan.id}>
                                    <div className="checkout-details__list-item">
                                        {`${plan.name} x ${plan.quantity}`}
                                        <button className="remove-plan-btn" onClick={()=>onRemoveFromPlanList(plan.id)}>
                                            <i className="fa fa-trash"/>
                                            {` Remove`}
                                        </button>
                                    </div>
                                </li>
                            )
                        })}
                    </ol>
                </div>
            </div>
        )
    }
}

class PlanComponent extends React.Component {
    //name,price,id,frequency,onAdd

    constructor(props){
        super(props);
        this.state = {
            quantity:0,
            disableButton:false
        }
    }

    setQuantity = (quantity) => {
        this.setState({
            quantity: Number(quantity),
            disableButton: Number(quantity) <= 0
        })
    };

    onAddPlan = () => {
        const {planId, planName, planType, planPrice, onAddPlan} = this.props;
        if(this.state.quantity > 0){
            onAddPlan(planId, planName+" "+planType, planPrice, this.state.quantity);
            // this.setState({
            //     disableButton:true
            // })
        }
    };

    render(){
        const {planType, planPrice, frequency, isAdded} = this.props;

        return (
          <div className={`plan_item ${isAdded ? 'added' : ''}`}>
              <h5 className="plan_name_text">{planType}</h5>
              <div className="price">
                  <p className="plan_pricetag">&#8358; {numberFormatter("#,###.00",(planPrice))}</p>
                  <h6 className="pricetag_duration">{`per ${frequency}`}</h6>
              </div>
              <div>
                  <input
                      className="plan_qty_select"
                      type="number"
                      placeholder="Quantity (e.g 5)"
                      onChange={(e)=>this.setQuantity(e.target.value)}
                      readOnly={isAdded}/>
              </div>
              <button
                  className="plan_select_btn"
                  onClick={this.onAddPlan}
                  disabled={isAdded || this.state.disableButton}>
                  Add to Plan List
              </button>
          </div>
        )
    }
}

class CheckoutList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            useNewCard: true,
            selectedCard: "",
            loadingNewCard: false,
            error: null
        }
    }

    toggleUseNewCard = () => {
        this.setState({
          useNewCard:!this.state.useNewCard
        })
    };

    showPrevCards = () => {
        const {clientDetails: {client_id, accessToken }} = this.props;
        if (!this.state.prevCards) {
            this.setState({loadingNewCard: true});
            clientService.getClientCards(client_id, accessToken)
            .then(data=>{
                this.setState(() => {
                    const oldState = {...this.state}
                    const prevCards = data.filter(card => card.is_reusable === 1)

                    return {
                        ...oldState,
                        prevCards,
                        selectedCard: prevCards.length > 0 ? prevCards[0].id : "",
                        loadingNewCard: false
                    }
                });
            }, e => {
                this.setState({
                    loadingNewCard: false,
                    error: {
                        message: e.response.data.message
                    }
                })
            });
        }
    }

    selectCard = (e) => {
        this.setState({selectedCard: e.target.value})
    }

    onPayNow = () => {
        const {plans,onCheckout} = this.props;
        const card_id = this.state.useNewCard ? undefined : this.state.selectedCard;
        const newInvoice = {
            cart: [...plans.map(plan=>({product_id:plan.id, no_of_enrollees: plan.quantity}))]
        };
        onCheckout(newInvoice, card_id);
    };


    render(){

        const {plans, paymentFreq} = this.props;

        return (
            <div>
                <h2 style={{margin:"1rem 2rem"}}>Heres a summary of your order. Please confirm and make changes where necessary. Click "PAY NOW" when done to pay.</h2>
                <br/>
                <br/>
                <table className="dashboard__table">
                    <thead>
                    <tr>
                        <th>Plans</th>
                        <th style={{textAlign:"right"}}>Unit Price (NGN)</th>
                        <th style={{textAlign:"right"}}>Sub-Total Price(NGN)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {plans.map((plan)=>
                        (
                            <tr key={plan.id}>
                                <td>{`${plan.name} x ${plan.quantity}`}</td>
                                <td style={{textAlign:"right"}}>&#8358; {numberFormatter("#,###.00",plan.price)}</td>
                                <td style={{textAlign:"right"}}>&#8358; {numberFormatter("#,###.00",plan.price * plan.quantity)}</td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "1.5rem 2rem",
                    alignItems: "center"
                }}>
                    <h2 style={{fontSize:"1.5rem"}}>TOTAL</h2>
                    <h3 style={{fontSize:"2rem"}}>&#8358; {numberFormatter("#,###.00",plans.reduce((sum,plan)=>sum+(plan.price * plan.quantity), 0))}</h3>
                </div>

                <hr/>

                <h3
                    style={{margin:"1.5rem 2rem", fontSize:"1.3rem"}}>
                    Selected Payment Frequency: <strong style={{color:"blue"}}>{paymentFreq.toUpperCase()}</strong>
                </h3>

                <div onChange={this.toggleUseNewCard} style={{margin:"1.5rem 2rem", fontSize:"1.5rem"}}>
                    Do you want to use a new card to pay?
                    <br/>
                    <br/>
                    <input type="radio" value="Yes" name="use_new_card" defaultChecked={this.state.useNewCard}/> Yes
                    {` `}
                    <input type="radio" value="No" name="use_new_card" defaultChecked={!this.state.useNewCard} onChange={() => this.showPrevCards()} /> No, Use my previous card.
                    <br/>
                    {this.state.loadingNewCard ? 
                        <p>...fetching previous cards</p> : 

                        this.state.error ? <p>{this.state.error.message}</p> :

                        this.state.prevCards ? (this.state.prevCards.length > 0 ?
                        
                            <div>
                                <p>Previous Cards:</p>
                                <select value={this.state.selectedCard} onChange={this.selectCard}>
                                    <option disabled value={""}>Select a previous card</option>
                                    {this.state.prevCards.map(card => 
                                        <option key={card.id} value={card.id}>{card.card_description}</option>
                                    )}
                                </select>
                            </div> :

                            <p>Couldn't find any previous cards kindly use a new one</p>) : null
                    }

                    <p style={{fontSize:"1.2rem",color:"#4c4c4c",fontStyle:"italic",margin:"1rem 0"}}>
                        *NOTE: If you use a new card, all previous/subsequent invoice charges will also be made on the new card.
                    </p>
                </div>


                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem"
                }}>
                    <button className="primary_button" onClick={this.onPayNow} style={{width:"100%", fontSize: "1.8rem"}}>
                        PAY NOW
                    </button>
                </div>
            </div>
        )
    }
}

const OrderSummaryModal = ({plans, clientDetails, paymentFreq, onCheckout, onClose}) => {
    return(
        <Modal title="Order Summary" onClose={onClose}>
            <CheckoutList plans={plans} paymentFreq={paymentFreq} clientDetails={clientDetails} onCheckout={onCheckout}/>
        </Modal>
    )
};

const NotificationModal = ({message, onClose}) => {
    return (
        <Modal title="Notification!" onClose={onClose}>
            <div style={{textAlign: "center",maxWidth:"500px"}}>
                <p style={{fontSize:"1.6rem",lineHeight:"2.4rem"}}>
                    {message}
                </p>
                <button className="primary_button" onClick={onClose}>DISMISS</button>
            </div>
        </Modal>
    )
};

OrderSummaryModal.propTypes = {
    plans: PropTypes.array,
    paymentFreq: PropTypes.string,
    onCheckout: PropTypes.func,
    onClose: PropTypes.func
};


CheckoutList.propTypes = {
    plans: PropTypes.array,
    paymentFreq: PropTypes.string,
    onCheckout: PropTypes.func
};

PlanComponent.propTypes = {
    planId: PropTypes.number,
    planName: PropTypes.string,
    planType: PropTypes.string,
    planPrice: PropTypes.string,
    frequency: PropTypes.string,
    onAddPlan: PropTypes.func,
    isAdded: PropTypes.bool
};

CheckOutHeader.propTypes = {
    selectedPlans : PropTypes.array,
    onCheckOut: PropTypes.func,
    onRemoveFromPlanList: PropTypes.func
};

NotificationModal.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func
};

export default NewInvoiceComponent;