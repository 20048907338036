import { CLIENT_ACTION_TYPES } from './actionTypes';
import clientService, { getAPIbaseUrl } from '../services/client';
import { sessionService } from 'redux-react-session';
import {
    DAARA_COOKIE_NAME,
    DAARA_TOKEN_PREFIX,
    getAccountsDashboardUrl
} from '../services/utilityService';
import features from '../services/daara-config.json';

//////////////////// ACTIONS //////////////////////////

export const login = () => {
    return {
        type: CLIENT_ACTION_TYPES.LOGIN
    };
};

export const loginSuccessful = (client) => {
    return {
        type: CLIENT_ACTION_TYPES.LOGIN_SUCCESS,
        data: client
    };
};

export const loginFailed = (error) => {
    return {
        type: CLIENT_ACTION_TYPES.LOGIN_FAIL,
        data: error
    };
};

export const daaraLogout = () => {
    return {
        type: CLIENT_ACTION_TYPES.DAARA_LOGOUT
    };
};

export const daaraLogoutSuccessful = (data) => {
    return {
        type: CLIENT_ACTION_TYPES.DAARA_LOGOUT_SUCCESS,
        data: { data }
    };
};

export const daaraLogoutFailed = (error) => {
    return {
        type: CLIENT_ACTION_TYPES.DAARA_LOGOUT_FAIL,
        data: {
            code: error.code,
            message: error.message
        }
    };
};

export const getOverView = () => {
    return {
        type: CLIENT_ACTION_TYPES.GET_OVERVIEW
    };
};

export const getOverViewSuccess = (data) => {
    return {
        type: CLIENT_ACTION_TYPES.GET_OVERVIEW_SUCCESS,
        data: data
    };
};

export const getOverViewFailed = (error) => {
    return {
        type: CLIENT_ACTION_TYPES.GET_OVERVIEW_FAIL,
        data: error
    };
};

export const getUsage = () => {
    return {
        type: CLIENT_ACTION_TYPES.GET_USAGE
    };
};

export const getUsageSuccess = (data) => {
    return {
        type: CLIENT_ACTION_TYPES.GET_USAGE_SUCCESS,
        data: data
    };
};

export const getUsageFailed = (error) => {
    return {
        type: CLIENT_ACTION_TYPES.GET_USAGE_FAIL,
        data: error
    };
};

export const updateProfile = () => {
    return {
        type: CLIENT_ACTION_TYPES.UPDATE_PROFILE
    };
};

export const updateProfileSuccessful = () => {
    return {
        type: CLIENT_ACTION_TYPES.UPDATE_PROFILE_SUCCESS
    };
};

export const updateProfileFailed = (error) => {
    return {
        type: CLIENT_ACTION_TYPES.UPDATE_PROFILE_FAIL,
        data: error
    };
};

export const changeLogo = () => {
    return {
        type: CLIENT_ACTION_TYPES.CHANGE_LOGO
    };
};

export const changeLogoSuccessful = (data) => {
    return {
        type: CLIENT_ACTION_TYPES.CHANGE_LOGO_SUCCESS,
        data: data
    };
};

export const changeLogoFailed = (error) => {
    return {
        type: CLIENT_ACTION_TYPES.CHANGE_LOGO_FAIL,
        data: error
    };
};

//////////////////// THUNKS //////////////////////////////

export const authClient = (to) => {
    return (dispatch) => {
        dispatch(login());
        clientService
            .auth()
            .then((client) => {
                saveSession(dispatch, client, to);
            })
            .catch(() => {
                window.location.href = `${getAccountsDashboardUrl()}/login${to ? '?r=' + to : ''}`;
            });
    };
};

export const loginClient = (email, password, history) => {
    return (dispatch) => {
        dispatch(login());
        clientService.login(email, password).then(
            (client) => {
                saveSession(dispatch, client, history);
            },
            (err) => {
                dispatch(
                    loginFailed({ message: err.response.data.message, code: err.response.status })
                );
            }
        );
    };
};

export const getOverViewData = (accessToken, clientId) => {
    return (dispatch) => {
        dispatch(getOverView());
        clientService.getOverViewData(accessToken, clientId).then(
            (data) => {
                dispatch(getOverViewSuccess(data));
            },
            (err) => {
                dispatch(
                    getOverViewFailed({
                        message: err.response.data.message,
                        code: err.response.status
                    })
                );
            }
        );
    };
};

export const getUsageData = (accessToken, numberOfDays, clientId) => {
    return (dispatch) => {
        dispatch(getUsage());
        clientService
            .getUsageData(accessToken, numberOfDays, clientId)
            .then(
                (data) => {
                    dispatch(getUsageSuccess(data));
                },
                (err) => {
                    dispatch(
                        getUsageFailed({
                            message: err.response.data.message,
                            code: err.response.status
                        })
                    );
                }
            )
            .catch((e) => {
                console.log(e);
            });
    };
};

export const updateClientProfile = (email, phone, logo_filename,logoURL, accessToken, clientId) => {
    return ((dispatch)=>{
        dispatch(updateProfile());
        clientService.updateProfile(email, phone, logo_filename, accessToken, clientId)
            .then(()=>{
                updateSessionUser(dispatch, email, phone, logo_filename, logoURL);
            }, (err)=>{
                dispatch(updateProfileFailed({message:err.response.data.message, code:err.response.status}))
            })
            .catch((e)=>{
                console.log(e);
            });
        })
    }

export const changeClientLogo = (logo, accessToken, clientId) => {
    return (dispatch) => {
        dispatch(changeLogo());
        clientService.changeLogo(logo, accessToken, clientId)
            .then(response=>{
                updateSessionLogo(dispatch, response.filename, response.file_url)
            })
    }
};

const updateSessionUser = (dispatch, email, phone, logo_filename, logoURL) => {
    sessionService.loadUser()
        .then((user)=>{
            const client = user;
            client.companyInfo.email = email;
            client.companyInfo.phoneNumber = phone;
            client.companyInfo.logo_filename = logo_filename
            client.companyInfo.logoUrl = logoURL;
            sessionService.saveUser(client)
                .then(()=>{
                    dispatch(updateProfileSuccessful())
                })
        });
};

const updateSessionLogo = (dispatch, logo_filename, file_url) => {
    sessionService.loadUser()
        .then((user)=>{
        const client = user;
        client.companyInfo.logo_filename = logo_filename;
        client.companyInfo.logoUrl = file_url
        sessionService.saveUser(client)
            .then(()=>dispatch(changeLogoSuccessful()))
        })
}

const saveSession = (dispatch, client, history) => {
    sessionService.saveSession(client.credentials.accessToken).then(() => {
        sessionService.saveUser(client).then(() => {
            dispatch(loginSuccessful(client, history));
        });
    });
};

export const logoutUserFromDaaraService = () => {
    return async (dispatch) => {
        dispatch(daaraLogout());
        await sessionService
            .loadUser()
            .then((user) => {
                if (features.account_service.auth) {
                    localStorage.removeItem(`${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`);
                }
                fetch(`${process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL}/logOut`, {
                    method: 'GET',
                    headers: new Headers({
                        Authorization: `Bearer ${user.credentials.daaraToken}`,
                        'Content-Type': 'application/json'
                    })
                })
                    .then((response) => dispatch(daaraLogoutSuccessful(response)))
                    .catch((error) => dispatch(daaraLogoutFailed(error)));
            })
            .catch((error) => dispatch(daaraLogoutFailed(error)));
    };
};

export const logoutUser = (referrer = ' ') => {
    return () => {
        return sessionService
            .deleteUser()
            .then(() => {
                sessionService.deleteSession().then(() => {
                    let destUrl = `${getAccountsDashboardUrl()}/logout?r=${referrer}`;
                    window.location.replace(destUrl);
                });
            })
            .catch((err) => {
                throw err;
            });
    };
};
