import Cookies from "js-cookie";

export const isDevelopment = () => {
  return (
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "daara"
  );
};

export const isStaging = () => {
  return (
    process.env.REACT_APP_ENV === "staging" ||
    process.env.REACT_APP_ENV === "daara-staging"
  );
};

export const isLocal = () => {
  return process.env.REACT_APP_ENV === "local";
};

export const isProduction = () => {
  return process.env.REACT_APP_ENV === "production";
};

export const getAccountsDashboardUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "local":
      return "http://localhost:3000";
    case "v3test":
    case "development":
      return "https://accounts.staging.reliancehmo.com"
    case "staging":
      return "https://accounts.staging.reliancehmo.com";
    default:
      return "https://accounts.reliancehmo.com";
  }
};

export const DOMAIN_COOKIE_NAME = (isProduction()) ? "rat" : "rat_" + process.env.REACT_APP_ENV;
export const DAARA_COOKIE_NAME = 'DAARA';
export const DAARA_TOKEN_PREFIX = process.env.REACT_APP_DAARA_TOKEN_PREFIX || 'CLIENT';
export const REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_NAME;

export const getCookie = (_name = DOMAIN_COOKIE_NAME) => {
  const cookie = Cookies.get(_name);
  return cookie;
};
